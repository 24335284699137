import { Link } from 'gatsby'
import React from 'react'
import {
  TwitterIcon,
  TwitterShareButton,
  LineShareButton,
  LineIcon
} from "react-share"

const Footer = props => (
  <footer id="footer">
    <section>
      <p>
        長らく放置していたサイトをとりあえず存在だけさせた。<br />
        このサイトに内容が追加されることはあるのか。
      </p>
    </section>
    <section>
      <h3>SHARE ME</h3>
      <div>
        <TwitterShareButton url={'sasuke287.com'} title={""} className="sns_button">
          <TwitterIcon size={60} />
        </TwitterShareButton>
        <LineShareButton url={'sasuke287.com'} title={""} className="sns_button">
          <LineIcon size={60} />
        </LineShareButton>
      </div>
    </section>
    <p className="copyright">
      &copy; 2021 sasuke287. Design: <a href="https://html5up.net">HTML5 UP</a>.
    </p>
  </footer >
)

export default Footer
